import Layout from 'common/layouts/Layout';
import {
  ButtonColor,
  PrimaryButton,
} from 'component-library/components/buttons/PrimaryButton';
import { Flex } from 'component-library/components/layout/Flex';
import { LinkWrapper } from 'component-library/components/links/LinkWrapper';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { SEO } from 'components/SEO';
import React from 'react';

const NotFound = () => (
  <Layout>
    <Navigation />
    <PageWrapper>
      <Flex alignItems="center" column marginTop={108}>
        <ResponsiveHeading alignment="center" variant={4} marginBottom={32}>
          Hmm, this page doesn&apos;t seem to exist
        </ResponsiveHeading>
        <LinkWrapper to="/advice">
          <PrimaryButton buttonColor={ButtonColor.Peach} to="/advice">
            Learn more about retirement →
          </PrimaryButton>
        </LinkWrapper>
      </Flex>
    </PageWrapper>
  </Layout>
);

export default NotFound;

export const Head = () => (
  <SEO title="Page not found" description="Page not found" />
);
